



































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import MapView from '@/components/core/Map.vue';
import ProductOther from '@/components/core/ProductOther.vue'
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import Card from '@/components/cart/Card.vue'
import {
    City
} from "@/store/city";
@Component({
    components: { MapView, ProductOther, Card, },
    computed: {},
})

export default class PostSaller extends Vue {
    dialog: boolean = false
    async created() {
        // await Core.switchLoad(true)
        await this.loadProduct();
        await this.loadFarm();
        await this.loadProducts()
        // await Core.switchLoad(false)
        this.response = true
    }
    products: any = null
    product: any = null
    farm: any = {}
    response: boolean = false;
    user: any = null
    profile: any = null

    allPages: number = 1
    page: number = 1

    async loadFarm() {
        // this.user = await Auth.getUser()
        // this.profile = await User.getProfileFull();
        // this.farm = await Core.getHttp(`/api/default/farm/${this.product.farm.id}/`)
        // console.log(this.farm )
    }

    async loadProduct() {
        let id = this.$route.query.product
        this.product = await Core.getHttp(`/api/default/products/${id}/ `)
        this.farm = this.product.farm
        this.profile = this.product.farm
        console.log(this.product)
    }
    async loadProducts() {
        let search = this.$route.query.search
        search = (search) ? `search=${search}` : ''
        this.products = await Core.getHttp(`/api/default/products/?farm=${this.farm.id}`)
        this.allPages = Math.ceil((this.products.count / this.products.result.length))

    }
    // async loadProducts() {
    //     this.products = await Core.getHttp(`/api/default/products/?farm=${this.product.farm.id}`)
    // }
    public async updateProduct(product: any) {
        let store = await Core.putHttp(`/api/default/products/${product.id}/`, product)
        if (store.id) {
            alert("Save product success")
            await this.loadProduct()
        }
    }

    private async handlePageChange(value: any) {
        let search = this.$route.query.search
        search = (search) ? `search=${search}` : ''
        this.products = await Core.getHttp(`/api/default/products/?farm=${this.farm.id}&product_type=${search}&page=${value}`)

    }

    async openBrowser(position: any) {
        let me = `${position.coords.latitude},${position.coords.longitude}`
        let to = `${this.farm.latitude},${this.farm.longitude}`
        console.log(me, `https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`);
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`, '_blank');
    }
    async openMap() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.openBrowser);
        } else {

        }

    }

    ximg(file: any) {
        return (file) ? process.env.VUE_APP_SERVER + '/' + file : 'https://images.pexels.com/photos/4052387/pexels-photo-4052387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
    }

}
